import { Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/guards/auth/auth.guard';
import { CalendarEventsResolver } from './modules/home/resolvers/events.resolver';
import { HomeCardsResolver } from './modules/home/resolvers/home-cards.resolver';
import { AuthenticatedLayoutComponent } from './shared/components/authenticated-layout/authenticated-layout.component';
import { TokenLoginGuard } from './modules/auth/guards/loginToken/loginToken.guard';
import { ErrorComponent } from './modules/error/containers/error/error.component';
import { SingleLayoutComponent } from './shared/components/single-layout/single-layout.component';
import { NotFoundComponent } from './modules/error/containers/not-found/not-found.component';
import { residentResolver } from './modules/resident/resolvers/resident/resident.resolver';
import { ResourcesGuard } from './modules/resources/guards/resources-guard';
import { MissingMediaErrorComponent } from './modules/error/containers/missing-media/missing-media.component';
import { facilityResolver } from './modules/manage/resolvers/facility/facility.resolver';
import { whoIsListeningResolver } from './modules/who-is-listening/resolvers/who-is-listening/who-is-listening.resolver';
import { DummyComponent } from './shared/components/dummy/dummy-component';
import { tvsResolver } from './modules/devices/resolvers/tvs.resolver';
import { DeviceGuard } from './modules/auth/guards/device/device.guard';


/**
 * I WANT TO COME UP WITH A WAY to load data automatically that is required on page load, and data that can loaded async for later use, like TV devices.
 * I dont want to add tv resolvers to routes because they are blocking.
 * I only have a non blocking load for the contentservice.
 * Maybe I can configure resolvers individually to load blocking or non blocking
 */

export const APP_ROUTES: Routes = [
  {
    path: 'auth',
    runGuardsAndResolvers: 'always',
    canActivate: [TokenLoginGuard, DeviceGuard],
    loadChildren: () => import('./modules/auth/auth.routing').then(m => m.AUTH_ROUTES)
  },
  {
    path: 'tv',
    // runGuardsAndResolvers: 'always',
    loadChildren: () => import('./modules/tv/tv.routing').then(m => m.TV_ROUTES)
  },

  {
    path: 'error',
    component: SingleLayoutComponent,
    children: [
      {
        path: '',
        component: ErrorComponent,
      },
      {
        path: 'missing-media',
        component: MissingMediaErrorComponent,
      },
    ]
  },
  {
    path: 'not-found',
    component: SingleLayoutComponent,
    children: [
      {
        path: '',
        component: NotFoundComponent,
      },
    ]
  },
  /** Authenticated view **/
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    canActivate: [
      AuthGuard,
      DeviceGuard
    ],
    data: {
      resolveAsync: ['tvs']
    },
    resolve: {
      facility: facilityResolver,
      resident: residentResolver,
      calenderEvents: CalendarEventsResolver,
      tvs: tvsResolver,
      homeCards: HomeCardsResolver,
      whoIsListening: whoIsListeningResolver,
    },
    children: [
      /** Home module **/
      {
        path: '',
        resolve: {
          // whoIsListening: whoIsListeningResolver,
        },
        canActivateChild: [AuthGuard],
        loadComponent: () => import('./modules/home/containers/overview/overview.component').then(m => m.OverviewComponent)
      },
      {
        path: 'search',
        outlet: 'v',
        loadComponent: () => import('./shared/components/dialogs/search-dialog/search-dialog.component').then(m => m.DialogEntryComponent)
      },
      /** Music module **/
      {
        path: 'music',
        data: {
          breadcrumbs: true,
        },
        loadChildren: () => import('./modules/music/music.routing').then(m => m.MUSIC_ROUTES)
      },
      /** Video module **/
      {
        path: 'videos',
        data: {
          breadcrumbs: true,
        },
        loadChildren: () => import('./modules/video/video.routing').then(m => m.VIDEO_ROUTES)
      },
      /** Devices module */
      {
        path: 'devices',
        loadChildren: () => import('./modules/devices/devices.routing').then(m => m.DEVICES_ROUTES)
      },
      /** Podcast module **/
      {
        path: 'podcasts',
        resolve: {
          facility: facilityResolver,
        },
        loadChildren: () => import('./modules/podcast/podcast.routing').then(m => m.PODCAST_ROUTES)
      },
      /** Resources module **/
      {
        path: 'resources',
        canActivate: [
          ResourcesGuard
        ],
        component: DummyComponent,
      },
      /** Management module */
      {
        path: 'manage',
        data: {},
        loadChildren: () => import('./modules/manage/manage.routing').then(m => m.MANAGE_ROUTES)
      },
      /** Resident module used for Resident edit*/
      {
        path: 'resident',
        loadChildren: () => import('./modules/resident/resident.routing').then(m => m.RESIDENT_ROUTES)
      },
      /** Fallback **/
      {
        path: '**',
        redirectTo: ''
      }
    ]
  }
];

