import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { map, switchMap } from 'rxjs';
import { DeviceTypes } from 'src/app/shared/models/global';
import { awsConfig } from '../../../../../../../../libs/includes/aws-export';

export const DeviceGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const deviceService = inject(DeviceService);

  // TEST REDIRECT FOR STAGING ONLY
  if (window.location.hostname !== 'app.staging.resparke.com') {
    return true;
  }
  return authService.isLoggedIn().pipe(
    switchMap(authState => {
      return deviceService.getDeviceType(authState.isLoggedIn);
    }),
    map(val => {
      if (val.type === DeviceTypes.TV) {
        window.location.href = awsConfig.tvAppUrl;
      }
      return true;
    })
  )
};
