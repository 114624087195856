import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { CategoryType, Dictionary, FavouritesCollection, GenericResponse, StaticData, ViewValue } from 'src/app/shared/models/global';
import { FamilyInviteStatus, Resident, ResidentFamily, ResidentPreferenceData, ResidentPreferenceDataRaw } from '@resparke/models';
import { Facility } from 'src/app/shared/services/facility/facility.service';
import { MusicCategory } from 'src/app/modules/music/models/music.model';
import { preferenceFields, ResidentFavourites, residentFields, ResidentGender, ResidentSubscriptionOutput, SuggestedContentCollection } from '../../models/resident.model';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { getSydneyDate } from '@resparke/utils';

type ResidentContentSuggestion = {
  residentId: String;
  residentName: String;
  facilityName: String;
  type: String;
  suggestions: [String];
}

@Injectable({
  providedIn: 'root'
})

export class ResidentService {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private appState: AppStateService,
  ) {
    const statement = `subscription onResidentUpdate {
      onResidentUpdate{
      facilityId
        preference {
          ${preferenceFields}
        }
        resident {
          ${residentFields}
        }
      }
    }`;

    this.apiService.subscription<ResidentSubscriptionOutput>({ statement, variables: undefined, type: 'onResidentUpdate' })
      .subscribe(val => {
        console.log('Subscription', val)
        if (val.resident) {
          val.resident = this.sanitiseResident(val.resident);
          this.stateUpdateResident(val.resident);
        }
        if (val.preference) {
          this.stateUpdateResidentPreferenceData(val.preference);
        }
      })
  }



  getCategories(params: {
    slug: string,
    type: CategoryType,
  }): Observable<ViewValue[]> {
    const data: Dictionary<any> = {
      type: params.type,
      slug: params.slug
    };

    const statement = `
      query getCategoriesV2($input: CategoryInput) {
        getCategoriesV2(input: $input) {
          category
          slug
          sort
        }
      }
    `;
    return this.apiService
      .graphql<MusicCategory[]>({ statement, variables: { input: data }, type: 'getCategoriesV2' })
      .pipe(
        map(result => result.map(val => ({ viewValue: val.category, value: val.slug })).sort((a, b) => a.viewValue < b.viewValue ? -1 : 1))
      )
  }

  getResident(input: { facilityId: string, residentId: string }): Observable<Resident> {
    const statement = `
      query getResidentV2($input: GetResidentInput!) {
        getResidentV2(input: $input) {
          id
          firstName
          lastName
          preferredName
          roomNumber
          preferenceId
          program
          locked
          familyStatus {
            status
            dateUpdated
          }
          data {
            approved
            firstName
            lastName
            preferredName
          }
        }
      }
    `;
    return this.apiService
      .graphql<Resident>({ statement, variables: { input }, type: 'getResidentV2' })
      .pipe(
        tap(resident => {
          // if resident is null because of incorrect residentId, early return
          if (!resident) {
            return;
          }
          resident = this.sanitiseResident(resident);

          const facilityId = this.appState.get<string>('currentFacilityId');
          const residentsCollection = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
          const residents = (residentsCollection[facilityId] || [])
            .filter(val => resident.id !== val.id);
          residentsCollection[facilityId] = [...residents, resident]
            .sort((a, b) => a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1);

          this.appState.setState('residentsCollection', residentsCollection)
          this.appState.setState('currentResident', resident);
        })
      )
  }

  getArtists(): Observable<ViewValue[]> {
    const statement = `
      query getArtists {
        getArtists {
          viewValue
          value
        }
      }
    `;
    return this.apiService
      .graphql<ViewValue[]>({ statement, variables: {}, type: 'getArtists' })
      .pipe(
        map(artists => artists.sort((a, b) => a.viewValue > b.viewValue ? 1 : -1))
      )

  }

  approveResidentDataChange(input: { preferenceId: string, topicId: string, approved: boolean }) {
    const statement = `
      mutation approveResidentDataChange($input: ApproveResidentDataChangeInput!) {
        approveResidentDataChange(input: $input)
      }
    `;
    return this.apiService
      .graphql<any>({ statement, variables: { input }, type: 'approveResidentDataChange' })
      .pipe(
        switchMap(result => {
          if (result) {
            return this.getResidentPreferenceData(input.preferenceId);
          }
          return of(false);
        })
      )
  }


  updateResident(params: { topic: keyof ResidentPreferenceData, data: Dictionary<any> }) {
    const { topic, data } = params;
    const resident = this.appState.get<Resident>('currentResident');
    const facilityId = this.appState.get<Facility>('currentFacilityId')
    const preferenceId = resident.preferenceId;

    const input: any = {
      residentId: resident.id,
      facilityId,
      preferenceId,
      data: {
        [topic]: data
      }
    }
    if (topic === 'profile') {
      input.resident = {
        firstName: data.firstName,
        lastName: data.lastName,
        preferredName: data.preferredName,
        roomNumber: data.roomNumber,
      }
      input.data.profile = {
        dob: data.dob,
        gender: data.gender,
      };
    }

    const statement = `
      mutation updateResident($input: UpdateResidentInput!) {
        updateResident(input: $input) {
          facilityId
          resident {
            ${residentFields}
          }
          preference {
            ${preferenceFields}
          }
        }
      }
    `;
    return this.apiService
      .graphql<any>({ statement, variables: { input }, type: 'updateResident' })
      // .pipe(
      //   tap(() => {

      //     if (topic === 'profile') {
      //       const currentResident = this.appState.get<Resident>('currentResident');
      //       const newResident = {
      //         ...currentResident,
      //         firstName: data.firstName,
      //         lastName: data.lastName,
      //         preferredName: data.preferredName,
      //         roomNumber: data.roomNumber,
      //       }
      //       this.appState.set<Resident>('currentResident', newResident);
      //     }

      //     // freaking Typescript giving me a hard time
      //     const currentResidentPreferenceData = this.appState.get<ResidentPreferenceData>('currentResidentPreferenceData') || {} as any;
      //     currentResidentPreferenceData[topic] = {
      //       ...currentResidentPreferenceData[topic],
      //       ...data
      //     } as ResidentPreferenceData;
      //     console.log('updating appstate', currentResidentPreferenceData)
      //     this.appState.setState<ResidentPreferenceData>('currentResidentPreferenceData', currentResidentPreferenceData);
      //   })
      // )
  }

  getResidentPreferenceData(preferenceId: string): Observable<boolean> {
    const input = {
      preferenceId,
    }
    const statement = `
      query getResidentPreferenceData($input: GetPreferencInput!) {
        getResidentPreferenceData(input: $input) {
          ${preferenceFields}
        }
      }
    `;
    return this.apiService
      .graphql<ResidentPreferenceDataRaw>({ statement, variables: { input }, type: 'getResidentPreferenceData' })
      .pipe(
        map(data => {
          const res: ResidentPreferenceData = {
            id: data.id,
            profile: data.profile.length ? data.profile[0] : undefined,
            music: data.music.length ? data.music[0] : undefined,
            cultureValuesBelieves: data.cultureValuesBelieves.length ? data.cultureValuesBelieves[0] : undefined,
            hobbiesInterestsSports: data.hobbiesInterestsSports.length ? data.hobbiesInterestsSports[0] : undefined,
            languageCountry: data.languageCountry.length ? data.languageCountry[0] : undefined,
            peoplePets: data.peoplePets.length ? data.peoplePets[0] : undefined,
            occupationsLife: data.occupationsLife.length ? data.occupationsLife[0] : undefined,
            conversationStarters: data.conversationStarters.length ? data.conversationStarters[0] : undefined,
            notes: data.notes.length ? data.notes[0] : undefined,
          }
          this.appState.setState('currentResidentPreferenceData', res);
          return true;
        })
      )
  }

  getResidentFavourites(preferenceId: string): Observable<FavouritesCollection> {
    const input = {
      preferenceId
    }
    const statement = `
      query getFavourites($input: GetPreferencInput!) {
        getFavourites(input: $input) {
          favourites {
            music {
              title
              artist
              path
              musicSource
              thumbnail
              dateAdded
              mediaId
            }
            video {
              title
              duration
              path
              videoSource
              kind
              thumbnail
              dateAdded
              mediaId
            }
          }
        }
      }
    `;
    return this.apiService
      .graphql<ResidentFavourites>({ statement, variables: { input }, type: 'getFavourites' })
      .pipe(
        map(preferences => {
          return preferences.favourites;
        }),
        tap(data => {
          this.appState.favouritesCollection = data;
        })
      )
  }

  getSuggestedContent(preferenceId: string) {
    const input = {
      preferenceId,
    }
    const contentPart = `
      id
      headerTitle
      headerIcon
      headerBgColor
      memory
      rows {
        thumbnail
        heading
        title
        type
        link
      }
    `;
    const statement = `
    query getSuggestedContent($input: GetPreferencInput!) {
      getSuggestedContent(input: $input) {
        suggestedContent {
          memoryBumbs {
            ${contentPart}
          }
          language {
            ${contentPart}
          }
          religion {
            ${contentPart}
          }
          music {
            ${contentPart}
          }
          occupations {
            ${contentPart}
          }
          interests {
            ${contentPart}
          }
          pets {
            ${contentPart}
          }
          sports {
            ${contentPart}
          }
        }
      }
    }
  `;
    return this.apiService
      .graphql<{ suggestedContent: SuggestedContentCollection }>({ statement, variables: { input }, type: 'getSuggestedContent' })
      .pipe(
        tap(data => {
          this.appState.currentResidentSuggestions = data.suggestedContent;
        })
      )
  }

  postSuggestedContentToSheets(input: ResidentContentSuggestion[]) {
    const statement = `
    mutation writeSuggestionsToSheet($input: [ResidentContentSuggestion]!) {
      writeSuggestionsToSheet(input: $input)
    }
  `;

    return this.apiService.graphql<string>({ statement, variables: { input }, type: 'writeSuggestionsToSheet' });
  }

  verifyUnlockCode(code: string) {
    const input = {
      code,
    }
    const statement = `
    query verifyUnlockCode($input: VerifyUnlockCodeInput!) {
      verifyUnlockCode(input: $input)
    }`;
    return this.apiService.graphql<boolean>({ statement, variables: { input }, type: 'verifyUnlockCode' });
  }

  getStaticData(input: { items: string[] }): Observable<ViewValue[]> {
    const statement = `
      query getStaticData($input: StaticDataInput!) {
          getStaticData(input: $input) {
            name
            data
          }
        }
    `;
    return this.apiService
      .graphql<StaticData[]>({ statement, variables: { input }, type: 'getStaticData', familyApi: true })
      .pipe(
        map<StaticData[], ViewValue[]>(res => {
          if (!res.length) {
            return [];
          } else if (res.length === 1) {
            return JSON.parse(res[0].data)
          }
        })
      )
  }

  addResident(input: {
    facilityId: string;
    dob: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    gender: ResidentGender;
    consent: boolean;
    applicant: string;
    roomNumber: string;
    countryOfBirth: string;
    primaryLanguage: string;
    religion: string;
    practiceReligion: boolean;
    suggestedReligion: string[],
  }) {

    const statement = `
      mutation addResidentV2($input: AddResidentInput!) {
        addResidentV2(input: $input) {
          id
          firstName
          lastName
          preferredName
          facilityId
          roomNumber
          preferenceId
          program
        }
      }
    `;
    return this.apiService
      .graphql<Resident>({ statement, variables: { input }, type: 'addResidentV2' })
      .pipe(
        tap(resident => {

          // IMPLEMENT SUBSCRIPTION
          if (resident) {
            const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
            resident = this.sanitiseResident(resident);
            residentsCol[input.facilityId] = [...residentsCol[input.facilityId], resident];
            this.appState.setState('residentsCollection', residentsCol);
          } else {
            console.error('Error creating resident');
          }
        })
      );
  }

  getResidents(facilityId: string): Observable<Resident[]> {

    const statement = `
      query getResidentsV2($facilityId: ID!) {
        getResidentsV2(facilityId: $facilityId) {
          id
          firstName
          lastName
          preferredName
          roomNumber
          preferenceId
          program
          locked
          familyStatus {
            status
            dateUpdated
          }
        }
      }
    `;
    return this.apiService
      .graphql<Resident[]>({ statement, variables: { facilityId }, type: 'getResidentsV2' })
      .pipe(
        map(residents => residents.length ?
          residents.sort((a, b) => a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1) : []
        ),
        map(residents => residents.map(resident => this.sanitiseResident(resident))),
        tap(residents => {
          const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
          residentsCol[facilityId] = residents;
          // trigger change detection
          this.appState.setState('residentsCollection', residentsCol);
        })
      )
  }

  getFamilyMember(input: { residentId: string }) {
    const statement = `
      query getFamilyMember($input: GetFamilyInput!) {
        getFamilyMember(input: $input) {
          id
          firstName
          lastName
          email
        }
      }
    `;
    return this.apiService
      .graphql<ResidentFamily>({ statement, variables: { input }, type: 'getFamilyMember', familyApi: true })
  }

  deleteResident(input: {
    facilityId: string;
    residentId: string;
    deleted?: boolean;
  }) {
    const statement = `
    mutation deleteResidentV2($input: DeleteResidentInput!) {
      deleteResidentV2(input: $input)
    }
  `;
    return this.apiService
      .graphql<string>({ statement, variables: { input }, type: 'deleteResidentV2' })
      .pipe(
        tap(() => {
          const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
          residentsCol[input.facilityId] = [...residentsCol[input.facilityId].filter(res => res.id !== input.residentId)];
          this.appState.setState('residentsCollection', residentsCol);
        })
      )
  }

  getFamilyLink(input: { residentId: string, facilityId: string, email: string }) {
    console.log('input', input)
    const statement = `
    mutation getFamilyLink($input: GetFamilyLinkInput!) {
      getFamilyLink(input: $input)
    }
  `;
    return this.apiService
      .graphql<string>({ statement, variables: { input }, type: 'getFamilyLink', familyApi: true })
  }

  addFamily(input: { firstName: string, lastName: string, email: string, residentId: string, facilityId: string }) {

    const statement = `
      mutation addFamily($input: AddFamilyInput!) {
        addFamily(input: $input) {
          status
          message
        }
      }
    `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'addFamily', familyApi: true })
      .pipe(
        tap(() => {
          const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
          const resident = residentsCol[input.facilityId].find(resident => resident.id === input.residentId);
          resident.familyStatus = [{ status: FamilyInviteStatus.DETAILS_SAVED, dateUpdated: getSydneyDate().valueOf() }, ...resident.familyStatus];
          this.appState.setState('residentsCollection', residentsCol);
        })
      )
  }

  inviteFamilyMember(input: { email: string, familyFirstName: string, residentFirstName: string, residentLastName: string, facilityName: string, facilityDisplayName: string, residentId: string, facilityId: string }) {
    const statement = `
      mutation inviteFamilyMember($input: InviteFamilyMemberInput!) {
        inviteFamilyMember(input: $input) {
          status
          message
        }
      }
    `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'addFamily', familyApi: true })
      .pipe(
        tap(() => {
          const residentsCol = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
          const resident = residentsCol[input.facilityId].find(resident => resident.id === input.residentId);
          resident.familyStatus = [{ status: FamilyInviteStatus.DETAILS_SENT, dateUpdated: getSydneyDate().valueOf() }, ...resident.familyStatus];
          this.appState.setState('residentsCollection', residentsCol);
        })
      )
  }

  updateResidentProgram(input: { facilityId: string, residentId: string, program: string[] }) {
    const statement = `
    mutation updateResidentProgram($input: ResidentProgramInput!) {
      updateResidentProgram(input: $input)
    }
  `;
    return this.apiService
      .graphql<string>({ statement, variables: { input }, type: 'updateResidentProgram' })
  }

  lockResident(residentId: string) {
    const input = {
      residentId,
    }
    const statement = `
    mutation lockResident($input: LockResidentInput!) {
      lockResident(input: $input) {
        status
        message
        payload
      }
    }
  `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'lockResident' })
      .pipe(
        map<GenericResponse, { username: string, tokenBody: string }>(data => JSON.parse(data.payload)),
        tap(() => this.authService.removeDeviceKey(residentId)),
        switchMap(data => {
          const authChallenge = decodeURIComponent(data.tokenBody);
          return this.authService.customChallengeLogin({
            username: data.username,
            challenge: authChallenge
          });
        })
      )
  }

  unlockResident(input: { residentId: string, reAuthenticate: boolean }) {
    const statement = `
      mutation unlockResident($input: UnlockResidentInput!) {
        unlockResident(input: $input) {
          facilityId
          status
          payload
        }
      }`;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'unlockResident' })
      .pipe(
        map<GenericResponse, { username: string, tokenBody: string, status?: string }>(data => JSON.parse(data.payload)),
        switchMap(data => {
          console.log(input.residentId);
          const facilityId = this.appState.get<string>('currentFacilityId');
          // remove locked resident from user collection
          const users = (this.appState.getUserCollection(facilityId) || [])
            .filter(val => val.userName !== input.residentId);
          console.log(users);
          this.appState.setUserCollection(facilityId, users);

          // only reAuthenticate if we have a tokenBody
          if (input.reAuthenticate && data.tokenBody) {
            return this.authService.passwordlessLogin(data);
          }
          return of(data);
        })
      );
  }

  private sanitiseResident(resident: Resident) {
    return {
      ...resident,
      familyStatus: Array.isArray(resident.familyStatus) && resident.familyStatus.length ? resident.familyStatus.reverse() : [{ status: FamilyInviteStatus.DETAILS_REQUIRED }]
    }
  }

  private stateUpdateResident(resident: Resident) {
    const currentResident = this.appState.get<Resident>('currentResident');
    if (currentResident && currentResident.id === resident.id) {
      this.appState.set('currentResident', resident);
    }

    const facilityId = this.appState.get<string>('currentFacilityId');
    const residentCollection = this.appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
    let residents = (residentCollection[facilityId] || []).filter(val => val.id !== resident.id);
    residents = [...residents, resident].sort((a, b) => a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1);
    residentCollection[facilityId] = residents;
    this.appState.set('residentsCollection', residentCollection);
  }

  private stateUpdateResidentPreferenceData(preference: ResidentPreferenceDataRaw) {
    const newPreference: ResidentPreferenceData = {
      id: preference.id,
      conversationStarters: preference.conversationStarters?.slice(-1)[0],
      cultureValuesBelieves: preference.cultureValuesBelieves?.slice(-1)[0],
      music: preference.music?.slice(-1)[0],
      languageCountry: preference.languageCountry?.slice(-1)[0],
      profile: preference.profile?.slice(-1)[0],
      peoplePets: preference.peoplePets?.slice(-1)[0],
      occupationsLife: preference.occupationsLife?.slice(-1)[0],
      hobbiesInterestsSports: preference.hobbiesInterestsSports?.slice(-1)[0],
      notes: preference.notes?.slice(-1)[0],
    } as ResidentPreferenceData;
    this.appState.setState<ResidentPreferenceData>('currentResidentPreferenceData', newPreference);
  }
}